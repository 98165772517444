import { Box, Button, Divider, Flex, Skeleton, Spinner, Stack, Text } from "@chakra-ui/react";
import { Icons, SkeletonCard, ToolTip, Typography } from "@reconvert/react-ui-component";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toggleHelpModal } from "../../../app/slice/appSlice";
import { Overview, selectConditionsState, setOverview } from "../../../app/slice/conditionSlice";
import useConditions from "../../../hooks/useConditions";
import { getVariant } from "../../../services/conditions.service";
import { getConditionOperatorVerbose, getConditionVerbose } from "../../../utils/getConditionText";
import ConditionCard from "../../ConditionCard";

const SkeletonComponent = () => (
  <Box display="grid" mb={"16px"}>
    <SkeletonCard>
      <Stack gap="13px">
        <Flex justifyContent="space-between">
          <Flex gap="8px">
            <Skeleton startColor="gray.400" endColor="gray.400" height="20px" width="140px" />
            <Skeleton startColor="gray.400" endColor="gray.400" height="20px" width="35px" />
          </Flex>
          <Flex gap="8px">
            <Skeleton startColor="gray.400" endColor="gray.400" height="24px" width="24px" />
            <Skeleton startColor="gray.400" endColor="gray.400" height="24px" width="24px" />
            <Skeleton startColor="gray.400" endColor="gray.400" height="24px" width="24px" />
          </Flex>
        </Flex>
        <Skeleton startColor="gray.400" endColor="gray.400" height="12px" width="252px" />
      </Stack>
    </SkeletonCard>
  </Box>
);

const Product = ({ productIds, variantList, id }: any) => {
  const { t } = useTranslation();
  const [productToShow, setProductToShow] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (productToShow?.length === 0) {
      getVariantDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIds, variantList]);

  const getVariantDetail = async () => {
    setLoading(true);

    try {
      const response = await getVariant(productIds);

      if (response) {
        setProductToShow(response);
      }
    } catch (error) {
      console.error("Error fetching variant detail:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box>
        {loading ? (
          <Flex>
            <Spinner variant={"ring"} size={"sm"} />
          </Flex>
        ) : (
          <Box maxHeight={"300px"} overflowY={"auto"}>
            {productToShow?.map((item: any, key: any) => (
              <Flex px="14px" py="14px" key={"item-" + key} justifyContent={"space-between"} alignItems={"center"}>
                <Flex gap="12px">
                  {item?.images.length > 0 ? (
                    <img alt={t("product image")} src={item?.images[0] || ""} height={"36px"} width={"36px"} />
                  ) : (
                    <Icons.ImagePreview w="36px" h="36px"/>
                  )}

                  <Box>
                    <Typography lineHeight={"20px"} variant="subheading2">
                      {item?.title}
                    </Typography>
                  </Box>
                </Flex>
              </Flex>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

const Conditions = () => {
  const dispatch = useAppDispatch();
  const {
    loading: isConditionDataLoading,
    overview,
    conditions,
    selectedConditionId,
  } = useAppSelector(selectConditionsState);
  const { updateConditionStatus, setSelectedConditionId } = useConditions();
  const { t } = useTranslation();
  const conditionsContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const defaultConditionCard = document?.getElementById("defaultCondition");

    if (defaultConditionCard) {
      conditionsContainerRef?.current?.scrollTo({
        top: conditionsContainerRef?.current?.scrollHeight,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document?.getElementById("defaultCondition")]);

  const handleAddConditionClick = useCallback(() => {
    dispatch(setOverview(Overview.ADD_CONDITION_VIEW));
    conditionsContainerRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [dispatch]);

  return (
    <Box sx={{ flexBasis: 96, h: "100%", py: 7, borderRight: "solid 1px #E4E8EE" }}>
      <Flex justifyContent={"space-between"} flexDirection="column" pr={4}>
        <Flex justifyContent={"space-between"} alignItems={"center"} w="100%">
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant="h2" pr={2} fontWeight={500}>
              {t("Conditions")}
            </Typography>
            <ToolTip
              placement="bottom"
              label={t(
                "Conditions allow you to segment your shoppers and display personalized checkout pages based on customer properties and actions.",
              )}
            >
              <span>
                <Icons.Question />
              </span>
            </ToolTip>
          </Flex>
          <Flex my="auto">
            <Button height={"100%"} variant="unstyled" display="flex" gap={2} onClick={handleAddConditionClick}>
              <Typography
                variant="subheading2"
                fontWeight={500}
                lineHeight={"20px"}
                _hover={{
                  borderBottomColor: "inherit",
                  borderBottom: "1px solid",
                }}
              >
                {t("Add a condition")}
              </Typography>{" "}
              <Icons.PlusCircle cursor="pointer" height={"16px"} width={"16px"} />
            </Button>
          </Flex>
        </Flex>
        <Flex justifyContent="start" width="100%" alignItems={"center"} gap={4} marginTop={"8px"} whiteSpace="nowrap">
          <Typography
            as="a"
            variant="small"
            color="var(--Link, #2F73DA)"
            fontWeight={500}
            cursor="pointer"
            onClick={() =>
              dispatch(
                toggleHelpModal({
                  link: "https://help.reconvert.io/en/article/how-do-conditions-work-12yqfsv/reader/?bust=1706881668418",
                  title: t("How do conditions work?").toString(),
                }),
              )
            }
          >
            {t("How do conditions work?")}
          </Typography>
        </Flex>
      </Flex>
      <Box pr={4}>
        <Divider orientation="horizontal" mt={"8px"} />
      </Box>
      <Box
        mt={4}
        textAlign="center"
        overflowY={"auto"}
        height={"calc(100vh - 200px)"}
        paddingLeft={"8px"}
        paddingRight={"8px"}
        ref={conditionsContainerRef}
      >
        {overview === Overview.ADD_CONDITION_VIEW && <SkeletonComponent />}
        {isConditionDataLoading ? (
          <Spinner variant={"ring"} size={"lg"} mt={20} />
        ) : (
          <>
            {!conditions || conditions.length === 0 ? (
              <ConditionCard
                title="Default condition"
                description="Horem ipsum dolor sit amet, consectetur adipiscing elit."
                isDisabled={false}
                isSelected={false}
                isConditionOn={false}
                mode="default"
                p={0}
              />
            ) : (
              conditions.map((con, index) => {
                return (
                  <ConditionCard
                    key={con?.id}
                    conditionId={con?.id}
                    title={con?.name}
                    description={
                      con?.isDefault
                        ? t("ReConvert displays the default condition when customers do not meet any other condition")
                        : con?.storeName
                    }
                    isDisabled={!con?.status}
                    isConditionOn={con?.status || false}
                    isSelected={selectedConditionId === con?.id}
                    onClick={e => setSelectedConditionId(con?.id)}
                    mode={con?.rule ? "collapsed" : "default"}
                    actions={[]}
                    p={2}
                    toggleCondition={updateConditionStatus}
                    condition={con}
                    id={index === conditions.length - 1 ? "defaultCondition" : ""}
                  >
                    <Box>
                      <Divider mb="12px" />
                      <Flex gap="12px">
                        <Divider
                          orientation="vertical"
                          height={"auto"}
                          borderLeft={"2px solid var(--Devider-2, #CBD5E0)"}
                        />
                        <Typography variant="body">
                          {getConditionVerbose(con?.rule?.all[0]?.extraFields?.condition)}&nbsp;
                          {getConditionOperatorVerbose(con?.rule?.all[0]?.extraFields?.operatorValue)} of&nbsp;
                          <ToolTip
                            placement="right"
                            label={
                              <Product
                                id={con?.id}
                                productIds={con?.rule?.all[0]?.extraFields?.productIds}
                                variantList={con?.rule?.all[0]?.value}
                              />
                            }
                          >
                            <Text as="u">
                              {con?.rule?.all[0]?.extraFields?.productIds?.length}{" "}
                              {`product${con?.rule?.all[0]?.extraFields?.productIds?.length > 1 ? "s" : ""}`}{" "}
                            </Text>
                          </ToolTip>
                        </Typography>
                      </Flex>
                    </Box>
                  </ConditionCard>
                );
              })
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Conditions;
