import { ChakraProvider } from "@chakra-ui/react";
import { ThemeProvider, theme } from "@reconvert/react-ui-component";
import { LoggerProvider } from "@reconvert/react-logger";
import { ENV, DD_CLIENT_TOKEN, DD_SERVICE, LAUNCH_DARKLY_CLIENT_KEY } from "./config";
import "./index.css";
import App from "./App";
import useFontLoader from "./hooks/useFontLoader";
import { withLDProvider } from "launchdarkly-react-client-sdk";

const AppWrapper = () => {
  const fontLoaded = useFontLoader();

  console.log({ fontLoaded });

  if (!fontLoaded) {
    return <></>;
  }

  return (
    <LoggerProvider env={ENV} ddClientToken={DD_CLIENT_TOKEN} ddService={DD_SERVICE} forceLogging>
      <ChakraProvider theme={theme}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </ChakraProvider>
    </LoggerProvider>
  );
};

export default withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_KEY!,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(AppWrapper);
