import { LDFlagSet } from "launchdarkly-react-client-sdk";
import { AllowedPlatform } from "../../models";
import { LaunchDarklyFlag } from "../../utils/constant";

export enum PageType {
  CHECKOUT = "CHECKOUT",
  PRODUCT_PAGE = "PRODUCT_PAGE",
  CART_PAGE = "CART_PAGE",
  POST_PURCHASE_PAGE1 = "POST_PURCHASE_PAGE1",
  POST_PURCHASE_PAGE2 = "POST_PURCHASE_PAGE2",
  THANK_YOU_PAGE = "THANK_YOU_PAGE",
  ORDER_STATUS_PAGE = "ORDER_STATUS_PAGE",
}


export const ShopifyCheckoutEditorPage: Record<PageType, string | null> = {
  [PageType.CHECKOUT]: "information",
  [PageType.PRODUCT_PAGE]: null,
  [PageType.CART_PAGE]: null,
  [PageType.POST_PURCHASE_PAGE1]: null,
  [PageType.POST_PURCHASE_PAGE2]: null,
  [PageType.THANK_YOU_PAGE]: "thank-you",
  [PageType.ORDER_STATUS_PAGE]: "customer-account-order-status",
};

export interface StoreInfo {
  storeClientId: number;
  storeUrl: string;
  shopName: string;
  shopOwner: string;
  email: string;
  currency?: string;
  currencySymbol?: string;
  dashboardVersion?: number;
  checkoutVersion?: number;
}

export interface View {
  isWidgetView: boolean;
  isAnalyticView: boolean;
}

export interface LinkItem {
  name: string;
  icon: React.JSX.Element;
  activeIcon: React.JSX.Element;
  type: PageType;
  allowedPlatform: AllowedPlatform;
  launchDarklyFlag?: LaunchDarklyFlag;
  disabled?: boolean;
  toolTip?: string;
}

export interface SideBarSection {
  name: string;
  links: LinkItem[];
  enabled: boolean;
}
export interface AppState {
  loading: boolean;
  storeInfo?: StoreInfo;
  error?: string;
  platformStoreId: string;
  activePage?: PageType;
  isSidebarCollapased: boolean;
  isSidebarSticked: boolean;
  showHelpModal: boolean;
  helpArticleLink?: string;
  helpArticleTitle?: string;
  view: View;
  ldFlags?: LDFlagSet;
  iFrameLink?: string;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  sideBarSections: SideBarSection[];
  checkoutProfileId?: string;
}
