import { useCallback, useEffect, useRef, useState } from "react";
import { ProductData } from "../models";
import { getProduct } from "../services/conditions.service";

interface SearchProductReturnType {
  searching: boolean;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  products: ProductData[];
  selectedProductIdObj: Record<string, string[]>;
  handleProductSelect: (productId: string, variantIds: string[], isChecked: boolean) => void;
}

const useSearchProduct = (value: Record<string, string[]>): SearchProductReturnType => {
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState<ProductData[]>([]);
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const [selectedProductIdObj, setSelectedProductIdObj] = useState<Record<string, string[]>>(
    JSON.parse(JSON.stringify(value)),
  );

  const fetchProduct = useCallback(async (searchTerm: string) => {
    setSearching(true);
    try {
      const response = await getProduct({
        q: searchTerm,
        limit: 20,
        fields: ["variants"],
        onlyActive: 1,
      });
      const regex = /^(0|-)+$/;

      if (response?.products) {
        const products = response.products.map((item: any) => ({
          ...item,
          id: item.id.split("/").pop() || "",
          variants: item.variants.map((variant: any) => ({
            ...variant,
            //Below variant id condition is only for wix, because wix not creating variant id for default variant
            //so we will set product id when there is no variant id available
            id: regex.test(variant.id.split("/").pop()) ? item.id.split("/").pop() : variant.id.split("/").pop() || "",
          })),
        }));

        setProducts(products);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setSearching(false);
    }
  }, []);

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      fetchProduct(searchTerm);
    }, 500);

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [searchTerm, fetchProduct]);

  useEffect(() => {
    fetchProduct(searchTerm);
  }, [searchTerm, fetchProduct]);

  const handleProductSelect = useCallback((productId: string, variantIds: string[], isChecked: boolean) => {
    setSelectedProductIdObj(prevSelectedProductIdObj => {
      if (isChecked) {
        return {
          ...prevSelectedProductIdObj,
          [productId]: [...(prevSelectedProductIdObj[productId] || []), ...variantIds],
        };
      }

      const newSelectedProductIdObj = { ...prevSelectedProductIdObj };
      const filteredVariants = (prevSelectedProductIdObj[productId] || []).filter(
        variantId => !variantIds.includes(variantId),
      );

      if (filteredVariants.length === 0) {
        delete newSelectedProductIdObj[productId];
        return newSelectedProductIdObj;
      }

      return {
        ...prevSelectedProductIdObj,
        [productId]: [...filteredVariants],
      };
    });
  }, []);

  return {
    searching,
    searchTerm,
    setSearchTerm,
    products,
    selectedProductIdObj,
    handleProductSelect,
  };
};

export default useSearchProduct;
