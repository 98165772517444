import { Button, Divider, Flex, Input, Select } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Typography } from "@reconvert/react-ui-component";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../app/hooks";
import { selectConditionsState } from "../../../../app/slice/conditionSlice";
import useConditions from "../../../../hooks/useConditions";
import useEditCondition from "../../../../hooks/useEditCondition";
import ProductSelector from "./ProductSelector";

const StyledSelect = styled(Select)`
  border: 1px solid #6a7381;
  transition: border-color 0.3s;

  &:hover {
    padding-left: 15px;
    border: 2px solid #6a7381;
  }
`;

const ConditionBox = () => {
  const { t } = useTranslation();
  const { updatingConditions } = useAppSelector(selectConditionsState);
  // const { selectedCondition } = useConditions();
  const { conditionData, updateConditionData, handleOnSubmit, canSave } = useEditCondition();

  return (
    <>
      <Flex py={4} flexDirection={"column"}>
        <Flex gap={"8px"} flexDirection={"column"}>
          {/* {!selectedCondition && ( */}
            <Flex gap={1} flexDirection={"column"}>
              <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
                {t("Condition name")}
              </Typography>
              <Input
                value={conditionData.name}
                onChange={e => updateConditionData({ name: e.target.value })}
                placeholder={t("My new condition")}
              />
            </Flex>
          {/* )} */}

          <Flex gap={1} flexDirection={"column"}>
            <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
              {t("Condition")}
            </Typography>
            <StyledSelect
              value={conditionData.fact}
              onChange={(e: any) => updateConditionData({ fact: e.target.value })}
            >
              <option value="SPECIFIC_PRODUCT">{t("Specific product")}</option>
            </StyledSelect>
          </Flex>
        </Flex>

        {conditionData.fact && (
          <>
            <Flex mt="16px" gap={1} flexDirection={"column"}>
              <Typography color={"#6A7381"} bg={"var(--Natural-100, #F9FAFC)"} variant="small" width={"max-content"}>
                {t("Product condition")}
              </Typography>
              <StyledSelect
                value={conditionData.condition}
                onChange={(e: any) => updateConditionData({ condition: e.target.value })}
              >
                <option value="atLeastOneProduct">{t("At least one product in the cart")}</option>
                <option value="entireOrder">{t("Every product in the cart")}</option>
              </StyledSelect>
            </Flex>

            <Flex mt="8px" gap={1} flexDirection={"column"}>
              <Typography color={"#6A7381"} bg={"var(--Natural-100, #F9FAFC)"} variant="small" width={"max-content"}>
                {t("Operator")}
              </Typography>
              <StyledSelect
                value={conditionData.operator}
                onChange={(e: any) => updateConditionData({ operator: e.target.value })}
              >
                <option value="is">{t("Is from the list")}</option>
                <option value="isNot">{t("Is not from the list")}</option>
              </StyledSelect>
            </Flex>

            <ProductSelector
              value={{ productIds: conditionData.productIds, variantIds: conditionData.variantIds }}
              setValue={(data: { productIds: string[]; variantIds: string[] }) => updateConditionData(data)}
            />

            {/* <>
              <Flex mt="8px" gap={1} flexDirection={"column"}>
                <InputGroup
                  onClick={() => {
                    handleSearchProductModal(true);
                  }}
                >
                  <InputLeftElement>
                    <Icons.Search color="#6A7381" />
                  </InputLeftElement>
                  <Input
                    cursor={"pointer"}
                    placeholder={t("Search for products")}
                    _hover={{
                      paddingLeft: "35px",
                      border: "2px solid #6a7381",
                    }}
                  />
                </InputGroup>
              </Flex>

              {isVariantLoading ? (
                <Flex justifyContent={"center"} mt="12px">
                  <Spinner />
                </Flex>
              ) : (
                [...productToShow]?.length > 0 && (
                  <Flex
                    width={"100%"}
                    borderRadius={"4px"}
                    borderLeft={"1px solid var(--Neutral-600, #6A7381)"}
                    borderRight={"1px solid var(--Neutral-600, #6A7381)"}
                    borderBottom={"1px solid var(--Neutral-600, #6A7381)"}
                    flexDirection={"column"}
                  >
                    <Box maxHeight={"300px"} overflowY={"auto"} mr={"4px"}>
                      {[...productToShow]?.slice(0, displayedProducts)?.map((item: any, key: any) => (
                        <Flex
                          px="24px"
                          py="14px"
                          key={"item-" + key}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Flex gap="12px">
                            <img src={item?.images[0]} height={"36px"} width={"36px"} />
                            <Box>
                              <Typography lineHeight={"20px"} variant="subheading2">
                                {item?.title}
                              </Typography>
                              <Typography variant="xsmall">Price ${item?.variants[0]?.price}</Typography>
                            </Box>
                          </Flex>
                          <Icons.CloseIcon
                            height={"16px"}
                            width={"16px"}
                            cursor={"pointer"}
                            onClick={() => handleRemoveProduct(item?.id)}
                          />
                        </Flex>
                      ))}
                    </Box>
                  </Flex>
                )
              )}
            </> */}

            {/* {productToShow?.length > 4 && productToShow?.length > displayedProducts && (
              <Flex mt={"8px"} justifyContent={"end"}>
                <Typography
                  onClick={() => handleLoadMore()}
                  cursor={"pointer"}
                  variant="body"
                  color={"#2F73DA"}
                  lineHeight={"16px"}
                  fontWeight={500}
                >
                  {t("+ 4 more products")}
                </Typography>
              </Flex>
            )} */}

            <Flex my={"24px"} gap="10px">
              <Button
                pt="16px"
                pb="8px"
                py="8px"
                width={"100%"}
                borderRadius={"4px"}
                background={"var(--Buttons-Primary-button, #121417)"}
                onClick={() => handleOnSubmit()}
                colorScheme="primary"
                isLoading={updatingConditions}
                isDisabled={!canSave}
              >
                <Typography variant="subheading2" lineHeight={"20px"} fontWeight={500} color={"#fff"}>
                  {t("Save")}
                </Typography>
              </Button>
              {/* <Button px="16px" py="8px" variant="unstyled" onClick={() => handleOnDelete()}>
                  <Typography variant="subheading2" lineHeight={"20px"} fontWeight={500} color={"#EA3323"}>
                    {t("Delete")}
                  </Typography>
                </Button> */}
            </Flex>
          </>
        )}
      </Flex>
      <Divider />
    </>
  );
};

export default React.memo(ConditionBox);
