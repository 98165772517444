import { Box, Center, Flex, Switch } from "@chakra-ui/react";
import { BorderedCard, Typography } from "@reconvert/react-ui-component";
import useVariants from "../../hooks/useVariants";
import AnalyticView from "./component/AnalyticView";
import WidgetView from "./component/WidgetView";

const Card = ({ variant }: any) => {
  const { selectedVariant, handleSelectedVariantUpdate, handleVariantStatusUpdate } = useVariants();

  return (
    <BorderedCard
      mx={"16px"}
      borderPosition="top"
      borderColor={
        selectedVariant?.slotId === variant?.slotId ? `var(--Secondary-600, #C78B35)` : `var(--Neutral-700, #363C45)`
      }
      borderRadius={"4px"}
    >
      <Box
        onClick={() => handleSelectedVariantUpdate(variant.id)}
        p={4}
        width={"100%"}
        background={`var(--Neutral-White, #FFF)`}
        boxShadow={"0px 2px 8px 0px rgba(106, 115, 129, 0.12);"}
        _hover={{
          background: !variant.status ? "#F7F9FC;" : "var(--Gray-1, #FCFCFE);",
        }}
        cursor={"pointer"}
        bg={!variant.status ? "#F7F9FC" : undefined}
        color={!variant.status ? "disable.color" : undefined}
      >
        <Flex gap={2} mb={3}>
          <Typography width={"100%"} fontWeight={500} variant="subheading1">
            {variant.name}
          </Typography>
          <Center onClick={e => e.stopPropagation()}>
            <Typography variant="small" fontWeight={400} mr={2} color="neutrals.900">
              {variant.status ? "On" : "Off"}
            </Typography>
            <Switch
              size="sm"
              key={variant.status}
              defaultChecked={variant.status}
              checked={variant.status}
              onChange={e => handleVariantStatusUpdate(variant.id, variant.status, e.target.checked)}
            />
          </Center>
          <Box>{/* <Icons.Eye color={"#6A7381"} cursor="pointer" /> */}</Box>
        </Flex>

        <AnalyticView variantId={variant?.id} variantStatus={variant?.status} />
        <WidgetView widgets={variant?.widgets} variantStatus={variant?.status} />
      </Box>
    </BorderedCard>
  );
};

export default Card;
