import { useEffect, useState } from "react";

const useFontLoader = () => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    if (document.fonts) {
      document.fonts
        .load("1em Work Sans")
        .then(() => {
          setFontLoaded(true);
        })
        .catch(error => {
          console.error("Font loading failed", error);
        });
    }
  }, []);

  return fontLoaded;
};

export default useFontLoader;
