import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../app/slice/appSlice";
import { selectConditionsState } from "../app/slice/conditionSlice";
import useConditions from "./useConditions";
import { Condition } from "../models";

interface ConditonData {
  name: string;
  fact: string;
  condition: string;
  operator: string;
  productIds: string[];
  variantIds: string[];
}

interface EditConditionReturnType {
  conditionData: ConditonData;
  updateConditionData: ({
    name,
    fact,
    condition,
    operator,
    productIds,
    variantIds,
  }: {
    name?: string;
    fact?: string;
    condition?: string;
    operator?: string;
    productIds?: string[];
    variantIds?: string[];
  }) => void;
  handleOnSubmit: () => void;
  canSave: boolean;
}

const initialConditonData: ConditonData = {
  name: "My new Condition",
  fact: "SPECIFIC_PRODUCT",
  condition: "atLeastOneProduct",
  operator: "is",
  productIds: [],
  variantIds: [],
};

const useEditCondition = (): EditConditionReturnType => {
  const { platformStoreId, activePage } = useAppSelector(selectAppState);
  const { conditions } = useAppSelector(selectConditionsState);
  const { t } = useTranslation();
  const { error } = useToast();
  const { selectedCondition, handleUpdateCondition, handleCreateCondition } = useConditions();
  const [conditionData, setConditionData] = useState<ConditonData>(initialConditonData);
  const getPlainConditionObject = useCallback(
    (condition: Condition) => ({
      name: condition.name,
      fact: condition?.rule?.all[0]?.fact,
      condition: condition?.rule?.all[0]?.extraFields?.condition,
      operator: condition?.rule?.all[0]?.extraFields?.operatorValue,
      productIds: condition?.rule?.all[0]?.extraFields?.productIds || [],
      variantIds: condition?.rule?.all[0]?.value || [],
    }),
    [],
  );

  useEffect(() => {
    if (selectedCondition) {
      setConditionData(getPlainConditionObject(selectedCondition));
    }
  }, [selectedCondition, getPlainConditionObject]);

  const updateConditionData = useCallback(
    ({
      name,
      fact,
      condition,
      operator,
      productIds,
      variantIds,
    }: {
      name?: string;
      fact?: string;
      condition?: string;
      operator?: string;
      productIds?: string[];
      variantIds?: string[];
    }) => {
      setConditionData({
        name: name !== undefined ? name : conditionData.name,
        fact: fact || conditionData.fact,
        condition: condition || conditionData.condition,
        operator: operator || conditionData.operator,
        productIds: productIds || conditionData.productIds,
        variantIds: variantIds || conditionData.variantIds,
      });
    },
    [conditionData],
  );

  const getOperatorValue = useCallback((condition: string, operator: string) => {
    if (condition && operator) {
      switch (`${condition}-${operator}`) {
        case "atLeastOneProduct-is":
          return "containsAtLeastOne";
        case "atLeastOneProduct-isNot":
          return "notContainsAtLeastOne";
        case "entireOrder-is":
          return "containsAll";
        case "entireOrder-isNot":
          return "notContainsAll";
        default:
          return "";
      }
    }

    return "";
  }, []);

  const highestPriority = useMemo(() => {
    return conditions?.reduce((maxPriority, condition) => {
      const priority = condition.priority || 0;

      return Math.max(maxPriority, priority);
    }, Number.NEGATIVE_INFINITY);
  }, [conditions]);

  const handleOnSubmit = useCallback(async () => {
    if (
      !conditionData.name ||
      !conditionData.condition ||
      !conditionData.operator ||
      conditionData.productIds.length === 0
    ) {
      return error(t("please fill all required field"));
    }

    const operatorString = getOperatorValue(conditionData.condition, conditionData.operator);
    const params = {
      platformStoreId: platformStoreId,
      location: activePage,
      name: conditionData.name,
      rule: {
        all: [
          {
            fact: conditionData.fact,
            operator: operatorString,
            value: conditionData.variantIds,
            extraFields: {
              condition: conditionData.condition,
              operatorValue: conditionData.operator,
              productIds: conditionData.productIds,
            },
          },
        ],
      },
    };

    if (selectedCondition) {
      handleUpdateCondition(selectedCondition.id, params);
    } else {
      handleCreateCondition({ ...params, priority: highestPriority + 1, name: conditionData.name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionData, platformStoreId, activePage, selectedCondition, highestPriority]);

  const canSave = useMemo(() => {
    if (!selectedCondition) {
      // Creating condition
      return !!(conditionData.name && conditionData.productIds.length > 0);
    }

    // Editing condition
    const oldCondition = getPlainConditionObject(selectedCondition);

    if (conditionData.productIds.length <= 0) {
      return false;
    }

    return (
      oldCondition.name !== conditionData.name ||
      oldCondition.condition !== conditionData.condition ||
      oldCondition.operator !== conditionData.operator ||
      oldCondition.productIds.length !== conditionData.productIds.length ||
      oldCondition.variantIds.length !== conditionData.variantIds.length ||
      conditionData.productIds.some(productId => !oldCondition.productIds.includes(productId)) ||
      conditionData.variantIds.some(variantId => !oldCondition.variantIds.includes(variantId))
    );
  }, [selectedCondition, conditionData, getPlainConditionObject]);

  return {
    conditionData,
    updateConditionData,
    handleOnSubmit,
    canSave,
  };
};

export default useEditCondition;
