import { ANALYTIC_BASE_URL, BACKEND_BASE_URL } from "../config";

export const API_BASE_URL = BACKEND_BASE_URL;
export const API_ANALYTIC_URL = ANALYTIC_BASE_URL;

//API variables
export const AUTH_LOGIN = "/auth/login";
export const GET_CONDITION = "/conditions";
export const GET_CONDITION_BY_ID = (id: string) => `/conditions/${id}`;
export const UPDATE_SLOT = (slotId: string) => `/slots/${slotId}`;
export const SLOT = `/slots`;
export const SLOT_LOGIN = `/slots/login`;
export const CREATE_VARIANTS = "/variants";
export const UPDATE_VARIANT = (id: string) => `/variants/${id}`;
export const UPDATE_CONDITIONS = (id: string) => `/conditions/${id}`;
export const DELETE_VARIANTS_BY_ID = (variantId: string) => `/variants/${variantId}`;
export const DELETE_CONDITIONS_BY_ID = (variantId: string) => `/conditions/${variantId}`;
export const DELETE_SLOT = (slotNumber: number, location: string) => `/slots/${slotNumber}?location=${location}`;
export const GET_PRODUCT = `/store/products-search`;
export const GET_VARIANT = (productIds: string) => `/store/variants/?productIds=${productIds}&limit=20`;
export const GET_ALL_VARIANT_ANALTICS = (fromDate: string, toDate: string, conditionId: string, location: string) =>
  `/analytics/getAggregatedData?base=SLOT_VARIANT&location=${location}&conditionIds=${conditionId}&fromDate=${fromDate}&toDate=${toDate}`;
export const GET_VARIANTS_ANALTICS = (fromDate: string, toDate: string, variantId: string, location: string) =>
  `/analytics/getAggregatedData?base=WIDGET&location=${location}&variantIds=${variantId}&fromDate=${fromDate}&toDate=${toDate}`;
export const CHECKOUT_PROFILE = "/editor/checkout_profile";

export type ApiError = { path?: string; message: string };
export type ErrorResponseBody = { success: false; errors: ApiError[] };
export type CreateOrReadResponseBody<T = any> = { success: true; data: T } | ErrorResponseBody;
export type UpdateOrDeleteResponseBody = { success: true; errors?: ApiError[] } | ErrorResponseBody;

//LAUNCH_DARKLY KEYS
export const LAUNCH_DARKLY_CHECKOUT_PAGE_KEY = "checkout-in-conditions-editor";
export const LAUNCH_DARKLY_POST_PURCHASE_PAGE_KEY = "post-purchase-in-conditions-editor";
export const LAUNCH_DARKLY_THANK_YOU_PAGE_KEY = "thank-you-page-in-conditions-editor";
export const LAUNCH_DARKLY_ORDER_STATUS_PAGE_KEY = "order-status-page-in-conditions-editor";

export type LaunchDarklyFlag =
  | typeof LAUNCH_DARKLY_CHECKOUT_PAGE_KEY
  | typeof LAUNCH_DARKLY_POST_PURCHASE_PAGE_KEY
  | typeof LAUNCH_DARKLY_THANK_YOU_PAGE_KEY
  | typeof LAUNCH_DARKLY_ORDER_STATUS_PAGE_KEY;
