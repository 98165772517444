import React from "react";
import { Box } from "@chakra-ui/react";
import ConditionBox from "../ConditionBox";
import ConditionTitle from "../ConditionTitle";

const AddConditionView = () => {
  return (
    <Box p={4} height="100%" overflowY="auto">
      <ConditionTitle />
      <ConditionBox />
    </Box>
  );
};

export default React.memo(AddConditionView);
