import { Box, Divider, Flex } from "@chakra-ui/react";
import { DateRangePicker, Icons, Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectAppState,
  toggleAnalyticsView,
  toggleHelpModal,
  toggleWidgetView,
  handleDateRangePicker,
} from "../../app/slice/appSlice";
import { useState } from "react";

const Header = () => {
  const { t } = useTranslation();
  const {
    view: { isWidgetView, isAnalyticView },
    dateRange,
  } = useAppSelector(selectAppState);

  const dispatch = useAppDispatch();

  const [isAnalyticsHovered, setIsAnalyticsHovered] = useState(false);
  const [isWidgetHovered, setIsWidgetHovered] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          flexShrink: 0,
          px: 4,
          boxShadow: "md",
          flexBasis: "60px",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Flex pr={5} gap="12px">
            {isAnalyticView && (
              <Box height="36px">
                <DateRangePicker
                  id="date"
                  name="MM / DD"
                  defaultFromDate={new Date(dateRange.startDate)}
                  defaultToDate={new Date(dateRange?.endDate)}
                  onDateValueChange={(startDate: Date | undefined, endDate: Date | undefined) => {
                    if (startDate && endDate) {
                      dispatch(handleDateRangePicker({ startDate: startDate, endDate: endDate }));
                    }
                  }}
                  size="sm"
                />
              </Box>
            )}

            <Flex
              p="8px"
              height={"36px"}
              width={"36px"}
              bg={isAnalyticView ? "neutrals.300" : "neutrals.200"}
              borderRadius={4}
              onClick={() => dispatch(toggleAnalyticsView())}
              cursor={"pointer"}
              _hover={{ background: "neutrals.300" }}
              onMouseEnter={() => setIsAnalyticsHovered(true)}
              onMouseLeave={() => setIsAnalyticsHovered(false)}
            >
              <Icons.Analytics
                height={"20px"}
                width={"20px"}
                color={isAnalyticView || isAnalyticsHovered ? "neutrals.700" : "neutrals.500"}
              />
            </Flex>

            <Flex
              p="8px"
              height={"36px"}
              width={"36px"}
              bg={isWidgetView ? "neutrals.300" : "neutrals.200"}
              borderRadius={4}
              onClick={() => dispatch(toggleWidgetView())}
              cursor={"pointer"}
              _hover={{ background: "neutrals.300" }}
              onMouseEnter={() => setIsWidgetHovered(true)}
              onMouseLeave={() => setIsWidgetHovered(false)}
            >
              <Icons.Widgets color={isWidgetView || isWidgetHovered ? "neutrals.700" : "neutrals.500"} />
            </Flex>
          </Flex>
          <Divider orientation="vertical" />
          <Box pl="24px" boxSizing="border-box">
            <Box
              cursor={"pointer"}
              borderRadius={"4px"}
              border="1px solid var(--Neutral-500, #9EA7B6)"
              background="var(--Neutral-White, #FFF)"
              padding={"8px 16px"}
              onClick={() =>
                dispatch(
                  toggleHelpModal({
                    link: "https://help.reconvert.io/en/article/how-to-use-the-flow-builder-120kpr5/reader/?bust=1706874298491",
                    title: t("How to use the Flow Builder").toString(),
                  }),
                )
              }
            >
              <Typography variant="subheading2" lineHeight={"20px"} fontWeight={500}>
                {t("Help")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
