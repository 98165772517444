import { Crisp } from "crisp-sdk-web";
import { CRISP_WEBSITE_ID } from "../config";

const setupCrispChat = (
  webSiteId: string | undefined,
  token: string,
  email: string,
  owner: string,
  companyInfo: { siteName: string; url: string },
  data: any,
) => {
  if (!webSiteId) return;

  try {
    Crisp.configure(webSiteId);
    Crisp.setTokenId(token);

    Crisp.user.setEmail(email);
    Crisp.user.setNickname(owner);
    Crisp.user.setCompany(companyInfo.siteName, {
      url: companyInfo.url,
    });

    Crisp.session.setData(data);
  } catch (error) {
    console.log("Error while configuring Crisp chat");
  }
};

const showCrispChat = () => {
  try {
    Crisp.chat && Crisp.chat.show();
  } catch (error) {
    console.log("Error while showing Crisp chat");
  }
};

const hideCrispChat = () => {
  try {
    Crisp.chat && Crisp.chat.hide();
  } catch (error) {
    console.log("Error while hiding Crisp chat");
  }
};

const isCrispVisible = () => {
  try {
    return CRISP_WEBSITE_ID && Crisp.chat && Crisp.chat.isVisible();
  } catch (error) {
    console.log("Error while checking crisp", error);
    return false;
  }
};

export { hideCrispChat, isCrispVisible, setupCrispChat, showCrispChat };
