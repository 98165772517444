import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setIFrameLink } from "../app/slice/appSlice";
import {
  createConditionAsync,
  deleteConditionAsync,
  selectCondition,
  selectConditionAsync,
  selectConditionsState,
  setUpdatingConditions,
  updateCondition,
  updateConditionAsync,
} from "../app/slice/conditionSlice";
import { Condition } from "../models";

interface ConditionsReturnType {
  selectedCondition: Condition | undefined;
  updateConditionStatus: (id: string, oldStatus: any, status: any) => void;
  handleUpdateConditionName: (id: string, oldName: any, name: any) => void;
  handleUpdateCondition: (id: string, body: any) => void;
  handleCreateCondition: (body: any) => void;
  setSelectedConditionId: (id: string | null) => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  deletingCondition: boolean;
  handleDeleteCondition: () => void;
  clostIFrame: () => void;
}

const useConditions = (): ConditionsReturnType => {
  const { t } = useTranslation();
  const { success, error } = useToast();
  const dispatch = useAppDispatch();
  const { conditions, selectedConditionId } = useAppSelector(selectConditionsState);
  const [deletingCondition, setDeletingCondition] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const selectedCondition = useMemo(
    () => (conditions || []).find((c: any) => c.id === selectedConditionId),
    [conditions, selectedConditionId],
  );

  const updateConditionStatus = useCallback(
    (id: string, oldStatus: any, status: any) => {
      dispatch(updateCondition({ id, status }));
      dispatch(updateConditionAsync({ id, body: { status } }))
        .unwrap()
        .then(() => success(t("Condition status changed successfully.")))
        .catch(() => {
          error(t("Failed to change condition status."));
          dispatch(updateCondition({ id, status: oldStatus }));
        });
    },
    [dispatch, success, error, t],
  );

  const handleUpdateConditionName = useCallback(
    (id: string, oldName: any, name: any) => {
      dispatch(updateCondition({ id, name }));
      dispatch(updateConditionAsync({ id, body: { name } }))
        .unwrap()
        .then(() => success(t("Condition name changed successfully.")))
        .catch(() => {
          error(t("Failed to change condition name."));
          dispatch(updateCondition({ id, name: oldName }));
        });
    },
    [dispatch, success, error, t],
  );

  const handleUpdateCondition = useCallback(
    (id: string, body: any) => {
      dispatch(setUpdatingConditions(true));
      dispatch(updateConditionAsync({ id, body }))
        .unwrap()
        .then(() => success(t("Condition updated successfully")))
        .catch(() => error(t("Error while saving condition")))
        .finally(() => dispatch(setUpdatingConditions(false)));
    },
    [dispatch, success, error, t],
  );

  const handleCreateCondition = useCallback(
    (body: any) => {
      dispatch(setUpdatingConditions(true));
      dispatch(createConditionAsync(body))
        .unwrap()
        .then(response => {
          success(t("Condition created successfully"));
          dispatch(selectCondition(response.data.id));
          dispatch(selectConditionAsync(response.data.id));
        })
        .catch(() => error(t("Error while creating condition")))
        .finally(() => dispatch(setUpdatingConditions(false)));
    },
    [dispatch, success, error, t],
  );

  const setSelectedConditionId = useCallback(
    (id: string | null) => {
      dispatch(selectCondition(id));

      if (id) {
        dispatch(selectConditionAsync(id))
          .unwrap()
          .catch(() => error(t("Failed to fetch condition details.")));
      }
    },
    [dispatch, error, t],
  );

  const handleDeleteCondition = useCallback(() => {
    if (!selectedConditionId) return;

    const index = (conditions || []).findIndex((c: any) => c.id === selectedConditionId);
    const nextSelectedConditionId = conditions[index + 1]?.id;

    setDeletingCondition(true);
    dispatch(deleteConditionAsync(selectedConditionId))
      .unwrap()
      .then(() => {
        success(t("Condition deleted successfully."));
        dispatch(selectCondition(nextSelectedConditionId));
        dispatch(selectConditionAsync(nextSelectedConditionId));
      })
      .catch(() => error(t("Error while deleting Condition.")))
      .finally(() => {
        setDeletingCondition(false);
        setIsDeleteModalOpen(false);
      });
  }, [dispatch, selectedConditionId, success, error, t, conditions]);

  const clostIFrame = useCallback(() => {
    dispatch(setIFrameLink(undefined));
    setSelectedConditionId(selectedConditionId);
  }, [dispatch, selectedConditionId, setSelectedConditionId]);

  return {
    selectedCondition,
    updateConditionStatus,
    handleUpdateConditionName,
    handleUpdateCondition,
    handleCreateCondition,
    setSelectedConditionId,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletingCondition,
    handleDeleteCondition,
    clostIFrame,
  };
};

export default useConditions;
