import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LDFlagSet } from "launchdarkly-react-client-sdk";
import { login } from "../../services/auth.service";
import { getAllCondition, getAllSlot, getCheckoutProfileId } from "../../services/conditions.service";
import { RootState } from "../store";
import { AppState, PageType, SideBarSection, View } from "./appSlice.model";
import { dateFormatYYYYMMDD, subtractDaysAndFormat } from "../../utils/helpers";
import { SECTIONS } from "../../components/Sidebar/Sidebar";

export const initialView: View = {
  isWidgetView: true,
  isAnalyticView: true,
};

const initialState: AppState = {
  loading: true,
  platformStoreId: "",
  isSidebarCollapased: false,
  isSidebarSticked: true,
  showHelpModal: false,
  view: initialView,
  dateRange: {
    startDate: subtractDaysAndFormat(29, "YYYY-MM-DD"),
    endDate: subtractDaysAndFormat(0, "YYYY-MM-DD"),
  },
  sideBarSections: SECTIONS,
  checkoutProfileId: undefined,
};

export const loginAsync = createAsyncThunk("app/login", async (token: string, { getState }) => {
  const state = getState() as RootState;

  // If already logged in then don't need to login again. It happens when we removes the query params after login in useAppInit hook
  if (state.app.storeInfo) {
    return { response: { data: state.app.storeInfo } };
  }

  const response = await login(token);

  return response;
});

export const getCheckoutProfileIdAsync = createAsyncThunk("app/getCheckoutProfileIdAsync", async (_, { getState }) => {
  const state = getState() as RootState;

  const response = await getCheckoutProfileId(state.app.platformStoreId);

  return (response?.data?.id as string) || undefined;
});

export const fetchConditionsAndSlotsAsync = createAsyncThunk("app/fetchConditionsAndSlots", async (_, { getState }) => {
  const state = getState() as RootState;

  if (!state.app.activePage) {
    return [];
  }

  const [conditions, slots] = await Promise.all([
    getAllCondition(state.app.activePage),
    getAllSlot(state.app.activePage),
  ]);

  return { conditions, slots, activePage: state.app.activePage };
});

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setPlatformStoreId: (state, action: PayloadAction<string>) => {
      state.platformStoreId = action.payload;

      if (!state.platformStoreId) {
        return;
      }

      try {
        const localStorageItem = localStorage.getItem(String(state.platformStoreId)) || "{}";
        const localStorageValue = JSON.parse(localStorageItem);

        const isWidgetView = Object.hasOwn(localStorageValue, "isWidgetViewEnable")
          ? localStorageValue.isWidgetViewEnable
          : state.view.isWidgetView;
        const isAnalyticView = Object.hasOwn(localStorageValue, "isAnalyticViewEnable")
          ? localStorageValue?.isAnalyticViewEnable
          : state.view.isAnalyticView;

        state.view = {
          isWidgetView,
          isAnalyticView,
        };
      } catch (error) {
        console.log(error);
      }
    },
    setActivePage: (state, action: PayloadAction<PageType | undefined>) => {
      state.activePage = action.payload;
    },
    toggleSideBar: state => {
      state.isSidebarCollapased = !state.isSidebarCollapased;
    },
    setIsSidebarSticked: (state, action: PayloadAction<boolean>) => {
      state.isSidebarSticked = action.payload;
    },
    setShowHelpModal: (state, action: PayloadAction<boolean>) => {
      state.showHelpModal = action.payload;
    },
    setHelpArticleLink: (state, action: PayloadAction<string | undefined>) => {
      state.helpArticleLink = action.payload;
    },
    toggleHelpModal: (state, action: PayloadAction<{ link: string; title: string } | undefined>) => {
      const iframeLink = action.payload;

      if (iframeLink) {
        state.helpArticleLink = iframeLink.link;
        state.helpArticleTitle = iframeLink.title;
      }

      if (state.showHelpModal) {
        state.helpArticleLink = "";
        state.helpArticleTitle = "";
      }

      state.showHelpModal = !state.showHelpModal;
    },
    toggleWidgetView: state => {
      state.view = {
        ...state.view,
        isWidgetView: !state.view.isWidgetView,
      };

      const localStorageItem = localStorage.getItem(String(state.platformStoreId)) || "{}";
      const localStorageValue = JSON.parse(localStorageItem);
      const localStorageItems = {
        ...localStorageValue,
        isWidgetViewEnable: state.view.isWidgetView,
      };

      localStorage.setItem(`${state.platformStoreId}`, JSON.stringify(localStorageItems));
    },
    toggleAnalyticsView: state => {
      state.view = {
        ...state.view,
        isAnalyticView: !state.view.isAnalyticView,
      };

      const localStorageItem = localStorage.getItem(String(state.platformStoreId)) || "{}";
      const localStorageValue = JSON.parse(localStorageItem);
      const localStorageItems = {
        ...localStorageValue,
        isAnalyticViewEnable: state.view.isAnalyticView,
      };

      localStorage.setItem(`${state.platformStoreId}`, JSON.stringify(localStorageItems));
    },
    setLDFlags: (state, action: PayloadAction<LDFlagSet>) => {
      state.ldFlags = action.payload;
    },
    setIFrameLink: (state, action: PayloadAction<string | undefined>) => {
      state.iFrameLink = action.payload;
    },
    handleDateRangePicker: (
      state,
      action: PayloadAction<{
        startDate: Date | null;
        endDate: Date | null;
      }>,
    ) => {
      state.dateRange.startDate = dateFormatYYYYMMDD(action.payload.startDate!);
      state.dateRange.endDate = dateFormatYYYYMMDD(action.payload.endDate!);
    },
    updateSideBarSections: (state, action: PayloadAction<SideBarSection[]>) => {
      state.sideBarSections = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginAsync.pending, state => {
        state.error = undefined;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        if (!action.payload.response.statusCode || action.payload.response.statusCode === 200) {
          state.storeInfo = action.payload.response.data;
        } else {
          state.error = "Authentication failed";
          state.loading = false;
        }
      })
      .addCase(loginAsync.rejected, (state, error) => {
        console.log("app/login", { error });
        state.storeInfo = undefined;
        state.error = "Authentication failed";
        state.loading = false;
      })
      .addCase(getCheckoutProfileIdAsync.fulfilled, (state, action) => {
        state.checkoutProfileId = action.payload;
      })
      .addCase(getCheckoutProfileIdAsync.rejected, (state, error) => {
        console.log("app/getCheckoutProfileIdAsync", { error });
        state.checkoutProfileId = undefined;
      });
  },
});

export const selectAppState = (state: RootState) => state.app;

export const {
  setLoading,
  setError,
  setPlatformStoreId,
  setActivePage,
  toggleSideBar,
  setIsSidebarSticked,
  setShowHelpModal,
  setHelpArticleLink,
  toggleHelpModal,
  toggleWidgetView,
  toggleAnalyticsView,
  setLDFlags,
  setIFrameLink,
  handleDateRangePicker,
  updateSideBarSections,
} = appSlice.actions;

export default appSlice.reducer;
