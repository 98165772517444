import { Typography } from "@reconvert/react-ui-component";
import React from "react";
import { useTranslation } from "react-i18next";
import useConditions from "../../../../hooks/useConditions";

const ConditionOverview = () => {
  const { selectedCondition } = useConditions();
  const { t } = useTranslation();

  return (
    <>
      <Typography pl={4} pt={4} variant="subheading2" mb={2} fontWeight={500}>
        {selectedCondition?.name}
      </Typography>
      <Typography pl={4} variant="body">
        {t("Click on a card to start working")}
      </Typography>
    </>
  );
};

export default React.memo(ConditionOverview);
