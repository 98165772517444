import { Box, Flex, Input, InputGroup, InputLeftElement, Spinner } from "@chakra-ui/react";
import { Icons, Typography } from "@reconvert/react-ui-component";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useProductSelector from "../../../../hooks/useProductSelector";
import SearchProductModal from "./AddConditionView/SearchProductModal";

interface ProductSelectorProps {
  value: { productIds: string[]; variantIds: string[] };
  setValue: (val: { productIds: string[]; variantIds: string[] }) => void;
}

const ProductSelector = ({ value, setValue }: ProductSelectorProps) => {
  const { t } = useTranslation();
  const [displayedProducts, setDisplayedProducts] = useState(4);
  const [isSearchProductModalShow, setIsSearchProductModalShow] = useState<boolean>(false);
  const { selectedProductIdObj, isProductsLoading, selectedProducts, updateSelectedProductIdObj, handleRemoveProduct } =
    useProductSelector({
      value,
      setValue,
    });

  const handleLoadMore = useCallback(() => {
    setDisplayedProducts(displayedProducts + 4);
  }, [displayedProducts]);

  const moreProductsText = useMemo(() => {
    const totalSelectedProducts = selectedProducts?.length || 0;

    if (totalSelectedProducts < 4) {
      return "";
    }

    const remainingProducts = totalSelectedProducts - displayedProducts;

    if (remainingProducts === 1) {
      return t("+ 1 more product");
    }

    return `+ ${remainingProducts} ${t(" more products")}`;
  }, [displayedProducts, selectedProducts.length]);

  return (
    <>
      <Flex mt="8px" gap={1} flexDirection={"column"}>
        <InputGroup
          onClick={() => {
            setIsSearchProductModalShow(true);
          }}
        >
          <InputLeftElement>
            <Icons.Search color="#6A7381" />
          </InputLeftElement>
          <Input
            cursor={"pointer"}
            placeholder={t("Search for products")}
            _hover={{
              paddingLeft: "35px",
              border: "2px solid #6a7381",
            }}
          />
        </InputGroup>
      </Flex>

      {isProductsLoading ? (
        <Flex justifyContent={"center"} mt="12px">
          <Spinner />
        </Flex>
      ) : (
        [...selectedProducts]?.length > 0 && (
          <Flex
            width={"100%"}
            borderRadius={"4px"}
            borderLeft={"1px solid var(--Neutral-600, #6A7381)"}
            borderRight={"1px solid var(--Neutral-600, #6A7381)"}
            borderBottom={"1px solid var(--Neutral-600, #6A7381)"}
            flexDirection={"column"}
          >
            <Box maxHeight={"300px"} overflowY={"auto"} mr={"4px"}>
              {[...selectedProducts]?.slice(0, displayedProducts)?.map((item: any, key: any) => (
                <Flex px="24px" py="14px" key={"item-" + key} justifyContent={"space-between"} alignItems={"center"}>
                  <Flex gap="12px">
                    {item?.images.length > 0 ? (
                      <img src={item?.images[0]} alt="" height={"36px"} width={"36px"} />
                    ) : (
                      <Icons.ImagePreview w="36px" h="36px"/>
                    )}

                    <Box>
                      <Typography lineHeight={"20px"} variant="subheading2">
                        {item?.title}
                      </Typography>
                    </Box>
                  </Flex>
                  <Icons.CloseIcon
                    height={"16px"}
                    width={"16px"}
                    cursor={"pointer"}
                    onClick={() => {
                      handleRemoveProduct(item?.id);
                    }}
                  />
                </Flex>
              ))}
            </Box>
          </Flex>
        )
      )}
      {selectedProducts?.length > 4 && selectedProducts?.length > displayedProducts && (
        <Flex mt={"8px"} justifyContent={"end"}>
          <Typography
            onClick={handleLoadMore}
            cursor={"pointer"}
            variant="body"
            color={"#2F73DA"}
            lineHeight={"16px"}
            fontWeight={500}
          >
            {moreProductsText}
          </Typography>
        </Flex>
      )}
      {isSearchProductModalShow && (
        <SearchProductModal
          value={selectedProductIdObj}
          setValue={data => {
            updateSelectedProductIdObj(data);
            setIsSearchProductModalShow(false);
          }}
          handleOnClose={() => setIsSearchProductModalShow(false)}
        />
      )}
    </>
  );
};

export default ProductSelector;
