import { Box, Button, Flex } from "@chakra-ui/react";
import { Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { Slot } from "../../models";
import SlotHeader from "../SlotHeader/SlotHeader";
import { useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../app/slice/appSlice";
import { PageType } from "../../app/slice/appSlice.model";
const EmptyState = ({
  slot,
  hideHeader,
  onAddWidget,
  isLoading,
  onSlotDelete,
}: {
  slot: Slot;
  hideHeader?: boolean;
  onAddWidget: any;
  isLoading: boolean;
  onSlotDelete: () => void;
}) => {
  const { t } = useTranslation();
  const { activePage } = useAppSelector(selectAppState);
  const isPOPPages = [PageType.POST_PURCHASE_PAGE1, PageType.POST_PURCHASE_PAGE2].includes(activePage!);

  return (
    <>
      {!hideHeader && <SlotHeader slot={slot} />}
      <Box mx={4}>
        <Box
          sx={{
            display: "flex",
            padding: "20px 60px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "14px",
            alignSelf: "stretch",
            borderRadius: "8px",
            border: "2px dashed var(--Neutral-500, #9EA7B6)",
          }}
        >
          <Typography maxWidth="100%" variant="subheading2" fontWeight={400} textAlign={"center"}>
            {t("When a slot is left empty, ReConvert will move on to the next condition.")}
          </Typography>

          <Flex gap="14px">
            <Button
              variant="secondary"
              size={"sm"}
              onClick={onAddWidget}
              isLoading={isLoading}
              fontSize={16}
              px={4}
              fontWeight={500}
            >
              {t("Add widgets")}
            </Button>

            {!isPOPPages && (
              <Button
                variant="ghost"
                size={"sm"}
                onClick={onSlotDelete}
                fontSize={16}
                px={4}
                fontWeight={500}
                color="primary.800"
              >
                {t("Delete slot")}
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default EmptyState;
