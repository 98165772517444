import { Box } from "@chakra-ui/react";
import { useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../app/slice/appSlice";
import { PageType } from "../../app/slice/appSlice.model";
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import HelpModal from "../HelpModal/HelpModal";

const Content = () => {
  const { activePage, isSidebarSticked } = useAppSelector(selectAppState);

  const getPageContent = () => {
    if (
      activePage === PageType.CHECKOUT ||
      activePage === PageType.POST_PURCHASE_PAGE1 ||
      activePage === PageType.POST_PURCHASE_PAGE2 ||
      activePage === PageType.THANK_YOU_PAGE ||
      activePage === PageType.ORDER_STATUS_PAGE
    ) {
      return <CheckoutPage />;
    }

    return <></>;
  };

  return (
    <>
      <Box
        sx={{
          bg: "var(--Neutral-200, #F7F9FC)",
          w: "100%",
          pl: `${!isSidebarSticked ? "80px" : "256px"}`,
        }}
      >
        {getPageContent()}
      </Box>
      <HelpModal />
    </>
  );
};

export default Content;
