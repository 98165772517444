import { Typography } from "@reconvert/react-ui-component";
import React from "react";

const Error = () => {
  return (
    <Typography textAlign="center" variant="h2">
      Oops!! Access denied.
    </Typography>
  );
};

export default Error;
