import React from "react";
import { useTranslation } from "react-i18next";
import { selectAppState } from "../../../app/slice/appSlice";
import { useAppSelector } from "../../../app/hooks";
import { Divider, Flex, ListItem, UnorderedList, useMediaQuery } from "@chakra-ui/react";
import { ToolTip, Typography } from "@reconvert/react-ui-component";
import { Widget } from "../../../models";
import { getDiscountText, removeHtmlElementsFromString } from "../../../utils/helpers";
import { getProductToDisplayVerbose } from "../../../utils/getConditionText";

const WidgetView = ({ widgets, variantStatus }: { widgets: Widget[]; variantStatus: boolean }) => {
  const [isLargerThan1284] = useMediaQuery("(min-width: 1284px)");
  const { t } = useTranslation();
  const {
    view: { isWidgetView },
  } = useAppSelector(selectAppState);

  return (
    <>
      {isWidgetView ? (
        <>
          <Divider />
          {widgets.length === 0 ? (
            <Typography
              my={3}
              textAlign="center"
              variant="subheading2"
              color={variantStatus ? "neutrals.600" : "disable.color"}
            >
              {t("No widgets will be shown on this slot")}
            </Typography>
          ) : (
            <>
              {/* <Typography as="i" color={variant.status ? "#6A7381" : "disable.color"} variant="tiny">
              {t("Hover the widget to see it’s details")}
            </Typography> */}
              <UnorderedList
                mt="8px"
                sx={{ display: "grid", gridTemplateColumns: isLargerThan1284 ? "1fr 1fr" : "1fr" }}
              >
                {widgets?.length > 0 &&
                  widgets
                    .filter((a: { parentWidgetId: any; type: string }) => !a.parentWidgetId && a.type !== "DIVIDER")
                    .map((widget: Widget, index: number) => {
                      return (
                        <ToolTip height={"100%"} placement="top" label={<WidgetDetail widgetInfo={widget} />}>
                          <ListItem fontSize={"14px"} key={index} width="fit-content">
                            <Typography variant="body">{widget.name || widget.type}</Typography>
                          </ListItem>
                        </ToolTip>
                      );
                    })}
              </UnorderedList>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const WidgetDetail = ({ widgetInfo }: { widgetInfo: Widget }) => {
  const { storeInfo } = useAppSelector(selectAppState);

  if (widgetInfo?.type === "TEXT") {
    return <WidgetInformation title={"Text"} text={widgetInfo?.text} />;
  }

  if (widgetInfo?.type === "BANNER") {
    return <WidgetInformation title={"Banner"} text={widgetInfo?.content?.title} />;
  }

  if (widgetInfo?.type === "MULTI_PRODUCT_UPSELL") {
    return (
      <Flex flexDirection={"column"}>
        <Typography variant="body">{widgetInfo?.name}</Typography>
        {widgetInfo?.widgets.map(widget => (
          <>
            <UnorderedList pl="5px" width={"100%"}>
              {widget?.type === "TEXT" && (
                <ListItem>
                  <WidgetInformation title={"Text"} text={widget?.text} />{" "}
                </ListItem>
              )}
              {widget?.type === "BANNER" && (
                <ListItem>
                  <WidgetInformation title={"Banner"} text={widget?.content?.title} />
                </ListItem>
              )}
              {widget?.type === "UPSELL" && (
                <>
                  <ListItem>
                    <WidgetInformation title={"Product"} />
                    {widget?.upsellInfo?.type && (
                      <Typography variant="xsmall">
                        -{" "}
                        {widget?.upsellInfo?.type === "SPECIFIC_PRODUCT"
                          ? widget?.upsellInfo?.settings?.SPECIFIC_PRODUCT?.productInfo?.title
                          : getProductToDisplayVerbose(widget?.upsellInfo?.type)}
                      </Typography>
                    )}
                    {widget?.blocks[3]?.discount?.enabled && (
                      <Typography variant="xsmall">
                        -{" "}
                        {getDiscountText(
                          widget?.blocks[3]?.discount?.discountValue,
                          widget?.blocks[3]?.discount?.type,
                          storeInfo?.currency!,
                        )}
                      </Typography>
                    )}
                  </ListItem>
                </>
              )}
            </UnorderedList>
          </>
        ))}
      </Flex>
    );
  }

  return <></>;
};

const WidgetInformation = ({ title, text }: { title: string; text?: string }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection={"column"} gap={"2px"}>
      <Typography variant="small">{t(title)}</Typography>
      {text && <Typography variant="xsmall">- {removeHtmlElementsFromString(text)}</Typography>}
    </Flex>
  );
};

export default WidgetView;
