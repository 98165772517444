import { useEffect, useState } from "react";
import { currencyFormat, dateDifferenceInDays, getCalculatedData, subtractDaysAndFormat } from "../utils/helpers";
import { getVariantAnalytics } from "../services/conditions.service";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../app/slice/appSlice";
import { selectConditionsState } from "../app/slice/conditionSlice";
import { useToast } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { Widget } from "../models";
import useVariants from "./useVariants";
import { AnalyticsRecord, selectVariantAnalysis } from "../app/slice/analyticsSlice";

export interface WidgetAnalytics {
  content: any;
  name: string;
  text?: string;
  type: string;
  category: string;
  isVisible: boolean;
  slotId: string;
  variantId: string;
  parentWidgetId?: string;
  blocks: any[];
  widgets: WidgetAnalytics[];
  createdAt?: string;
  updatedAt?: string;
  id: string;
  upsellInfo?: {
    type: string;
    settings: any;
  };
  impressions: string;
  clicks: string;
  conversions: string;
  revenue: string;
  cvr: string;
  ctr: string;
  declined: string;
  declinedDiffPercentage: string;
  impressionsDiffPercentage: string;
  clicksDiffPercentage: string;
  conversionsDiffPercentage: string;
  revenueDiffPercentage: string;
  cvrDiffPercentage: string;
  ctrDiffPercentage: string;
}

interface AnalyticReturnType {
  variantAnalyticData: WidgetAnalytics[];
  loading: boolean;
}

const useVariantAnalytics = (): AnalyticReturnType => {
  const { t } = useTranslation();
  const {
    dateRange,
    activePage,
    storeInfo,
    view: { isAnalyticView },
  } = useAppSelector(selectAppState);
  const { error } = useToast();
  const { selectedVariantId, selectedConditionId, conditions } = useAppSelector(selectConditionsState);
  const { variantsAnalytics } = useAppSelector(selectVariantAnalysis);
  const { selectedVariant } = useVariants();
  const [variantAnalyticData, setVariantAnalyticData] = useState<WidgetAnalytics[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const variantsAnalyticDetail = variantsAnalytics.filter(
      (analytics: AnalyticsRecord) => analytics.baseId === selectedVariantId,
    )[0];

    const selectedVariantWidgets = selectedVariant?.widgets;

    const uniqueSpecificVariantWidget = selectedVariantWidgets.reduce(
      (acc: any[], item: { category: string; id: string; parentWidgetId: string; type: string }) => {
        if (item.category === "CONTAINER") {
          const widgets = selectedVariantWidgets.filter(
            (widget: AnalyticsRecord) => widget.parentWidgetId === item.id && widget.type !== "DIVIDER",
          );

          acc.push({ ...item, widgets });
        } else if (!item.parentWidgetId && item.type !== "DIVIDER") {
          acc.push(item);
        }

        return acc;
      },
      [],
    );

    const getVariantAnalyticsDetail = async () => {
      setLoading(true);
      try {
        const dateDifference = dateDifferenceInDays(new Date(dateRange?.startDate), new Date(dateRange?.endDate));

        const [currentAnalytics, previousAnalytics] = await Promise.all([
          getVariantAnalytics(dateRange?.startDate, dateRange?.endDate, selectedVariantId!, activePage!),
          getVariantAnalytics(
            subtractDaysAndFormat(dateDifference, "YYYY-MM-DD", dateRange?.startDate),
            dateRange?.startDate,
            selectedVariantId!,
            activePage!,
          ),
        ]);

        const calculatedData = getCalculatedData(currentAnalytics.data, previousAnalytics.data);

        const updatedWidgets = uniqueSpecificVariantWidget?.map((widget: Widget) => {
          if (widget?.category === "CONTAINER") {
            const updatedSubWidgets = widget?.widgets?.map((subWid: Widget) => {
              const analyticsData = calculatedData.find(analyticsItem => analyticsItem?.baseId === subWid?.id);

              if (analyticsData) {
                return {
                  ...subWid,
                  impressions: analyticsData["impressions"] + "" || 0,
                  clicks: analyticsData["clicks"] + "" || 0,
                  conversions: analyticsData["conversions"] + "" || 0,
                  revenue: `${currencyFormat(storeInfo?.currency!, +analyticsData["revenue"])}` || 0,
                  cvr: `${analyticsData["cvr"]}%` || 0,
                  ctr: analyticsData["ctr"] + "" || 0,
                  declined: analyticsData["declined"] + "" || 0,
                  impressionsDiffPercentage: analyticsData["impressionsDiffPercentage"] || 0,
                  clicksDiffPercentage: analyticsData["clicksDiffPercentage"] || 0,
                  conversionsDiffPercentage: analyticsData["conversionsDiffPercentage"] || 0,
                  revenueDiffPercentage: analyticsData["revenueDiffPercentage"] || 0,
                  cvrDiffPercentage: analyticsData["cvrDiffPercentage"] || 0,
                  ctrDiffPercentage: analyticsData["ctrDiffPercentage"] || 0,
                  declinedDiffPercentage: analyticsData["declinedDiffPercentage"] || 0,
                };
              } else {
                return subWid;
              }
            });

            return { ...widget, widgets: updatedSubWidgets };
          } else {
            const analyticsData = calculatedData.find(analyticsItem => analyticsItem?.baseId === widget?.id);

            if (analyticsData) {
              return {
                ...widget,
                impressions: analyticsData["impressions"] + "" || 0,
                clicks: analyticsData["clicks"] + "" || 0,
                conversions: analyticsData["conversions"] + "" || 0,
                revenue: `${currencyFormat(storeInfo?.currency!, +analyticsData["revenue"])}` || 0,
                cvr: `${analyticsData["cvr"]}%` || 0,
                ctr: analyticsData["ctr"] + "" || 0,
                declined: analyticsData["declined"] + "" || 0,
                impressionsDiffPercentage: analyticsData["impressionsDiffPercentage"] || 0,
                clicksDiffPercentage: analyticsData["clicksDiffPercentage"] || 0,
                conversionsDiffPercentage: analyticsData["conversionsDiffPercentage"] || 0,
                revenueDiffPercentage: analyticsData["revenueDiffPercentage"] || 0,
                cvrDiffPercentage: analyticsData["cvrDiffPercentage"] || 0,
                ctrDiffPercentage: analyticsData["ctrDiffPercentage"] || 0,
                declinedDiffPercentage: analyticsData["declinedDiffPercentage"] || 0,
              };
            } else {
              return widget;
            }
          }
        });

        if (updatedWidgets) {
          setVariantAnalyticData(updatedWidgets as unknown as WidgetAnalytics[]);
        } else {
          setVariantAnalyticData([]);
        }
      } catch (e) {
        error(t("Error while fetching analytics"));
      } finally {
        setLoading(false);
      }
    };

    if (selectedVariantId && variantsAnalyticDetail && isAnalyticView) {
      getVariantAnalyticsDetail();
    } else {
      setVariantAnalyticData(uniqueSpecificVariantWidget);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activePage,
    conditions,
    dateRange?.endDate,
    dateRange?.startDate,
    selectedConditionId,
    selectedVariantId,
    variantsAnalytics,
  ]);

  return { variantAnalyticData, loading };
};

export default useVariantAnalytics;
