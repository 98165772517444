import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAppState, setIFrameLink } from "../app/slice/appSlice";
import { createSlotAsync, deleteSlotAsync, setCreatingSlot, updateSlot, updateSlotAsync } from "../app/slice/slotSlice";
import { WIDGET_EDITOR_URL } from "../config";

interface SlotReturnType {
  handleCreateSlot: () => void;
  handleSlotNameUpdate: (id: string, oldName: any, name: any) => void;
  openDeleteSlotModal: boolean;
  setOpenDeleteSlotModal: (value: boolean) => void;
  handleSlotDelete: (slotNo: number) => void;
  deletingSlot: boolean;
}

const useSlots = (): SlotReturnType => {
  const { t } = useTranslation();
  const { success, error } = useToast();
  const { activePage, platformStoreId } = useAppSelector(selectAppState);
  const dispatch = useAppDispatch();
  const [openDeleteSlotModal, setOpenDeleteSlotModal] = useState(false);
  const [deletingSlot, setDeletingSlot] = useState(false);

  const handleCreateSlot = useCallback(() => {
    if (window.opener && window.top !== window) {
      window.opener.postMessage(
        {
          eventType: "slot-created",
          data: { platformStoreId },
        },
        "*",
      );
    }
    
    dispatch(setCreatingSlot(true));
    dispatch(createSlotAsync())
      .unwrap()
      .then(res => {
        success(`${t("Slot created successfully.")}`);
        dispatch(
          setIFrameLink(
            `${WIDGET_EDITOR_URL}?variant=${res.data.variants[0].id}${
              activePage! ? `&type=${activePage!}` : ""
            }&platformStoreId=${platformStoreId}&isNewVariant=true`,
          ),
        );
      })
      .catch(() => error(`${t("Error while creating slot.")}`));
  }, [dispatch, success, error, t, activePage, platformStoreId]);

  const handleSlotNameUpdate = useCallback(
    (id: string, oldName: any, name: any) => {
      dispatch(updateSlot({ id, name }));
      dispatch(updateSlotAsync({ id, body: { name } }))
        .unwrap()
        .catch(() => {
          error(t("Failed to change slot name."));
          dispatch(updateSlot({ id, name: oldName }));
        });
    },
    [dispatch, error, t],
  );

  const handleSlotDelete = useCallback(
    (slotNo: number) => {
      setDeletingSlot(true);
      dispatch(deleteSlotAsync(slotNo))
        .unwrap()
        .then(res => {
          success(t("Slot deleted successfully."));
          setDeletingSlot(false);
          setOpenDeleteSlotModal(false);
        })
        .catch(() => {
          setDeletingSlot(false);
          error(t("Error while deleting slot."));
        });
    },
    [dispatch, t, success, error],
  );

  return {
    handleCreateSlot,
    handleSlotNameUpdate,
    openDeleteSlotModal,
    setOpenDeleteSlotModal,
    handleSlotDelete,
    deletingSlot,
  };
};

export default useSlots;
