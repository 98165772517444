import { Flex } from "@chakra-ui/react";
import { AlertDialogModal, Typography } from "@reconvert/react-ui-component";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import useSlots from "../../hooks/useSlots";
import useVariants from "../../hooks/useVariants";
import { Slot } from "../../models";
import { EmptyState } from "../EmptyState";
import SlotHeader from "../SlotHeader/SlotHeader";
import VariantCard from "../VariantCard";

const SlotVariant = ({ slot, variants }: { slot: Slot; variants: any }) => {
  const { openDeleteSlotModal, setOpenDeleteSlotModal, handleSlotDelete, deletingSlot } = useSlots();
  const { creatingVariant, handleAddWidget } = useVariants();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  return (
    <>
      {!variants?.length ? (
        <EmptyState
          onAddWidget={() => handleAddWidget(slot)}
          slot={slot}
          isLoading={creatingVariant}
          onSlotDelete={() => setOpenDeleteSlotModal(true)}
        />
      ) : (
        <Flex direction={"column"}>
          <SlotHeader slot={slot} />
          <>
            {variants?.length ? (
              <VariantCard variant={variants?.[0]} />
            ) : (
              <EmptyState
                onAddWidget={() => handleAddWidget(slot, variants?.[0]?.id)}
                onSlotDelete={() => setOpenDeleteSlotModal(true)}
                hideHeader={true}
                slot={slot}
                isLoading={creatingVariant}
              />
            )}
          </>
        </Flex>
      )}

      <AlertDialogModal
        title={`${t("Are you sure you want to delete slot")} ${slot.slotNo} ${slot.name}?`}
        okButtonText={`${t("Delete Slot")} ${slot.slotNo}`}
        size={"2xl"}
        isOpen={openDeleteSlotModal}
        leastDestructiveRef={cancelRef}
        onClose={() => setOpenDeleteSlotModal(false)}
        okButtonOnClick={() => handleSlotDelete(slot.slotNo)}
        cancelButtonOnClick={() => setOpenDeleteSlotModal(false)}
        confirmLoading={deletingSlot}
      >
        <Typography variant="subheading2">
          {t("If you delete this slot, it will be removed from")} <strong>{t("all")}</strong>{" "}
          {t("conditions that use it.")}
        </Typography>
      </AlertDialogModal>
    </>
  );
};

export default React.memo(SlotVariant);
