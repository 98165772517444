import { Box, Flex, Grid } from "@chakra-ui/react";
import { Icons, Typography } from "@reconvert/react-ui-component";

const AnalyticsRow = ({
  name,
  value,
  change,
  isHighlighted,
  isForSidebar,
  variantStatus = true,
  iconSize,
}: {
  name: string;
  value: string;
  change: string;
  isHighlighted?: boolean;
  isForSidebar?: boolean;
  variantStatus?: boolean;
  iconSize?: string;
}) => {
  return (
    <Grid
      templateColumns={isForSidebar ? "140px 83px min-content auto" : "auto min-content 20px 50px"}
      alignItems="center"
      mx={isForSidebar ? "16px" : ""}
      gap={isForSidebar ? "" : "4px"}
    >
      <Box>
        <Typography
          id="name"
          fontWeight={isHighlighted ? "medium" : "normal"}
          variant="body"
          color={variantStatus ? "neutrals.900" : "disable.color"}
        >
          {name}
        </Typography>{" "}
      </Box>
      {value ? (
        <>
          <Flex justifyContent={isForSidebar ? "start" : "end"}>
            <Typography
              id="value"
              fontWeight={isHighlighted ? "medium" : "normal"}
              variant="body"
              width={"min-content"}
              color={variantStatus ? "neutrals.900" : "disable.color"}
            >
              {value}
            </Typography>
          </Flex>
          <Flex alignItems={"center"}>
            {variantStatus ? (
              change.startsWith("-") ? (
                <Icons.AnalyticsArrowsDecrease size={iconSize} />
              ) : (
                <Icons.AnalyticsArrowsIncrease size={iconSize} />
              )
            ) : (
              <Icons.AnalyticsArrowsDisable isDecrease={change.startsWith("-")} />
            )}
          </Flex>
          <Box>
            <Typography
              id="changes"
              color={
                variantStatus
                  ? change.startsWith("-")
                    ? `var(--Error-800, #B1383C);`
                    : `var(--Success--800, #559D72);`
                  : "disable.color"
              }
              variant="body"
            >
              {change.startsWith("-") ? change : `+${change}`}
            </Typography>
          </Box>
        </>
      ) : (
        <Flex justifyContent={"end"}>
          <Typography variant="small">-</Typography>
        </Flex>
      )}
    </Grid>
  );
};

export default AnalyticsRow;
