import React, { useMemo } from "react";
import { Box, Center, Divider, Flex, Hide, Show, Spinner, useMediaQuery } from "@chakra-ui/react";
import { TFunction } from "i18next";
import { useAppSelector } from "../../../app/hooks";
import { selectAppState } from "../../../app/slice/appSlice";
import { AnalyticsRecord, selectVariantAnalysis } from "../../../app/slice/analyticsSlice";
import { useTranslation } from "react-i18next";
import AnalyticsRow from "./AnalyticsRow";
import { Icons, Typography } from "@reconvert/react-ui-component";
import { currencyFormat } from "../../../utils/helpers";
import { PageType } from "../../../app/slice/appSlice.model";

interface VariantAnalyticsRow {
  name: string;
  currentValue: string;
  diffInPercentage: string;
}

export const prepareVariantAnalyticsRow = (
  t: TFunction<"translation", undefined>,
  activePage: PageType,
  variantsAnalyticDetail?: AnalyticsRecord,
): VariantAnalyticsRow[] =>
  activePage === PageType.CHECKOUT ||
  activePage === PageType.THANK_YOU_PAGE ||
  activePage === PageType.ORDER_STATUS_PAGE
    ? [
        {
          name: t("Revenue"),
          currentValue: variantsAnalyticDetail?.revenue?.toString() ?? "0",
          diffInPercentage: variantsAnalyticDetail?.revenueDiffPercentage?.toString() ?? "0",
        },
        {
          name: t("Impressions"),
          currentValue: variantsAnalyticDetail?.impressions?.toString() ?? "0",
          diffInPercentage: variantsAnalyticDetail?.impressionsDiffPercentage?.toString() ?? "0%",
        },
        {
          name: t("Clicks"),
          currentValue: variantsAnalyticDetail?.clicks?.toString() ?? "0",
          diffInPercentage: variantsAnalyticDetail?.clicksDiffPercentage?.toString() ?? "0%",
        },
        {
          name: t("Conversions"),
          currentValue: variantsAnalyticDetail?.conversions?.toString() ?? "0",
          diffInPercentage: variantsAnalyticDetail?.conversionsDiffPercentage?.toString() ?? "0%",
        },
        {
          name: t("CVR"),
          currentValue: variantsAnalyticDetail?.cvr?.toString() + "%" ?? "0",
          diffInPercentage: variantsAnalyticDetail?.cvrDiffPercentage?.toString() ?? "0%",
        },
      ]
    : [
        {
          name: t("Revenue"),
          currentValue: variantsAnalyticDetail?.revenue?.toString() ?? "0",
          diffInPercentage: variantsAnalyticDetail?.revenueDiffPercentage?.toString() ?? "0",
        },
        {
          name: t("Impressions"),
          currentValue: variantsAnalyticDetail?.impressions?.toString() ?? "0",
          diffInPercentage: variantsAnalyticDetail?.impressionsDiffPercentage?.toString() ?? "0%",
        },
        {
          name: t("Conversions"),
          currentValue: variantsAnalyticDetail?.conversions?.toString() ?? "0",
          diffInPercentage: variantsAnalyticDetail?.conversionsDiffPercentage?.toString() ?? "0%",
        },
        {
          name: t("Declined offers"),
          currentValue: variantsAnalyticDetail?.declined?.toString() ?? "0",
          diffInPercentage: variantsAnalyticDetail?.declinedDiffPercentage?.toString() ?? "0%",
        },
        {
          name: t("CVR"),
          currentValue: variantsAnalyticDetail?.cvr?.toString() + "%" ?? "0",
          diffInPercentage: variantsAnalyticDetail?.cvrDiffPercentage?.toString() ?? "0%",
        },
      ];

const AnalyticView = ({ variantId, variantStatus }: { variantId: string; variantStatus: boolean }) => {
  const [isLargerThan1464] = useMediaQuery("(min-width: 1464px)");
  const [isLargerThan1573] = useMediaQuery("(min-width: 1573px)");
  const { t } = useTranslation();
  const {
    view: { isAnalyticView },
    activePage,
  } = useAppSelector(selectAppState);
  const { storeInfo } = useAppSelector(selectAppState);
  const { variantsAnalytics, loading, error } = useAppSelector(selectVariantAnalysis);

  const analyticsDetails = useMemo(() => {
    const variantsAnalyticDetail = variantsAnalytics.filter(
      (analytics: AnalyticsRecord) => analytics.baseId === variantId,
    )[0];

    if (variantsAnalyticDetail) {
      return prepareVariantAnalyticsRow(t, activePage!, variantsAnalyticDetail);
    } else {
      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantsAnalytics, variantId, activePage]);

  return (
    <Box>
      {isAnalyticView ? (
        <>
          <Divider mt={3} />

          {loading ? (
            <Center pt={2} pb={2}>
              <Spinner variant={"ring"} size={"sm"} />
            </Center>
          ) : (
            <>
              {!error && analyticsDetails ? (
                <Flex width={"100%"}>
                  <Flex
                    direction={"column"}
                    pr={1}
                    width={isLargerThan1573 ? "65%" : isLargerThan1464 ? "70%" : "100%"}
                    pt={2}
                    pb={2}
                  >
                    <Hide breakpoint="(min-width: 1464px)">
                      {analyticsDetails?.[0]?.currentValue ? (
                        <AnalyticsRow
                          name="Revenue"
                          value={`${currencyFormat(storeInfo?.currency!, +analyticsDetails?.[0]?.currentValue)}`}
                          change={analyticsDetails?.[0]?.diffInPercentage}
                          isHighlighted
                          variantStatus={variantStatus}
                          iconSize={"sm"}
                        />
                      ) : (
                        <Typography variant="xsmall" fontWeight={500}>
                          -
                        </Typography>
                      )}
                    </Hide>
                    {analyticsDetails?.slice(1)?.map((val: VariantAnalyticsRow, index: number) => (
                      <>
                        <AnalyticsRow
                          key={index}
                          name={val?.name}
                          value={val?.currentValue}
                          change={val?.diffInPercentage}
                          variantStatus={variantStatus}
                          iconSize={"sm"}
                        />
                      </>
                    ))}
                  </Flex>
                  <Show breakpoint="(min-width: 1464px)">
                    <Box p={1}>
                      <Divider orientation="vertical" />
                    </Box>
                    <Flex direction="column" justifyContent={"center"} alignItems={"center"} flex={1}>
                      <Typography variant="subheading2" fontWeight={500}>
                        {t("Revenue")}
                      </Typography>
                      {analyticsDetails?.[0]?.currentValue ? (
                        <>
                          <Typography variant="subheading2">
                            {currencyFormat(storeInfo?.currency!, +analyticsDetails?.[0]?.currentValue)}
                          </Typography>
                          <Center gap={"4px"}>
                            <Typography variant="small">
                              {variantStatus ? (
                                analyticsDetails?.[0]?.diffInPercentage?.startsWith("-") ? (
                                  <Icons.AnalyticsArrowsDecrease size="sm" />
                                ) : (
                                  <Icons.AnalyticsArrowsIncrease size="sm" />
                                )
                              ) : (
                                <Icons.AnalyticsArrowsDisable
                                  isDecrease={analyticsDetails?.[0]?.diffInPercentage?.startsWith("-")}
                                />
                              )}
                            </Typography>
                            <Typography
                              color={
                                variantStatus
                                  ? analyticsDetails?.[0]?.diffInPercentage.startsWith("-")
                                    ? `var(--Error-800, #B1383C);`
                                    : `var(--Success--800, #559D72);`
                                  : "disable.color"
                              }
                              variant="xsmall"
                            >
                              {analyticsDetails?.[0]?.diffInPercentage}
                            </Typography>
                          </Center>
                        </>
                      ) : (
                        <Typography variant="xsmall" fontWeight={500}>
                          -
                        </Typography>
                      )}
                    </Flex>
                  </Show>
                </Flex>
              ) : (
                <Typography
                  my={3}
                  textAlign="center"
                  variant="subheading2"
                  color={error ? "error.700" : variantStatus ? "neutrals.600" : "disable.color"}
                >
                  {error
                    ? t("It seems we're having trouble fetching the Analytics information you requested")
                    : t("Your analytics will appear here once there's data to show")}
                </Typography>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AnalyticView;
