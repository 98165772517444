import { Card } from "@chakra-ui/react";
import { Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";

const EmptyCard = () => {
  const { t } = useTranslation();

  return (
    <Card
      border="2px solid var(--Devider-2, #CBD5E0)"
      bg="var(--Neutral-300, #F0F3F7)"
      mt={4}
      p={3}
      textAlign="center"
      h={20}
      borderRadius={8}
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body" color="var(--Neutral-600, #6A7381)">
        {t("Please finish creating the condition on the right sidebar")}
      </Typography>
      <Typography variant="body" color="var(--Neutral-600, #6A7381)">
        {t("To start edit the slots and widgets")}
      </Typography>
    </Card>
  );
};

export default EmptyCard;
