import { useCallback, useEffect, useState } from "react";
import { ProductData } from "../models";
import { getVariant } from "../services/conditions.service";

interface ProductSelectorProps {
  value: { productIds: string[]; variantIds: string[] };
  setValue: (val: { productIds: string[]; variantIds: string[] }) => void;
}

interface ProductSelectorReturnType {
  selectedProductIdObj: Record<string, string[]>;
  isProductsLoading: boolean;
  selectedProducts: ProductData[];
  handleRemoveProduct: (productId: string) => void;
  updateSelectedProductIdObj: (value: Record<string, string[]>) => void;
}

const useProductSelector = ({ value, setValue }: ProductSelectorProps): ProductSelectorReturnType => {
  const [selectedProductIdObj, setSelectedProductIdObj] = useState<Record<string, string[]>>(
    JSON.parse(JSON.stringify(value)),
  );
  const [selectedProducts, setSelectedProducts] = useState<ProductData[]>([]);
  const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!value.productIds?.length) {
      setSelectedProducts([]);
    } else {
      const hasNewProduct = value.productIds.some(id => !selectedProducts.find(p => p.id === id));

      if (hasNewProduct) {
        getSelectedProducts();
      } else {
        setSelectedProducts(selectedProducts.filter(p => value.productIds.includes(p.id)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.productIds]);

  const getSelectedProducts = useCallback(async () => {
    setIsProductsLoading(true);
    const response = await getVariant(value.productIds);

    if (response) {
      const regex = /^(0|-)+$/;
      let products = response?.map((item: any) => {
        return {
          ...item,
          id: item?.id.split("/").pop(),
          variants: item?.variants?.map((variant: any) => {
            return {
              ...variant,
              id: regex.test(variant?.id?.split("/")?.pop()) ? item?.id.split("/").pop() : variant?.id?.split("/")?.pop(),
            };
          }),
        };
      });

      let newSelectedProductIdObj: any = {};

      value.productIds?.forEach((productId: any) => {
        products.forEach((product: any) => {
          if (product.id === productId) {
            newSelectedProductIdObj[productId] = product.variants
              .filter((variant: any) => value.variantIds.includes(variant?.id))
              .map((value: any) => value.id);
          }
        });
      });

      setSelectedProductIdObj(newSelectedProductIdObj);
      setSelectedProducts(products);
    }

    setIsProductsLoading(false);
  }, [value]);

  const updateSelectedProductIdObj = (value: Record<string, string[]>) => {
    setSelectedProductIdObj(value);
    const productIds = Object.keys(value)?.filter(key => key !== "productIds" && key !== "variantIds");
    const variantIds = Object.values(value).flat();

    setValue({ productIds, variantIds });
  };

  const handleRemoveProduct = (productId: string) => {
    const newValue = { ...selectedProductIdObj };

    delete newValue[productId];
    updateSelectedProductIdObj(newValue);
  };

  return {
    selectedProductIdObj,
    isProductsLoading,
    selectedProducts,
    handleRemoveProduct,
    updateSelectedProductIdObj,
  };
};

export default useProductSelector;
