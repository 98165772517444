import { Center, Spinner } from "@chakra-ui/react";
import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { Overview, selectConditionsState } from "../../../../app/slice/conditionSlice";
import AddConditionView from "./AddConditionView";
import ConditionOverview from "./ConditionOverview";
import EditConditionView from "./EditConditionView";
import VariantOverview from "./VariantOverview";

const ConditionCreationSidebar = () => {
  const { overview, loading } = useAppSelector(selectConditionsState);

  if (loading) {
    return (
      <Center>
        <Spinner variant={"ring"} size={"lg"} mt={20} />
      </Center>
    );
  }

  switch (overview) {
    case Overview.CONDITION_VIEW:
      return <ConditionOverview />;
    case Overview.VARIANT_VIEW:
      return <VariantOverview />;
    case Overview.ADD_CONDITION_VIEW:
      return <AddConditionView />;
    case Overview.EDIT_CONDITION_VIEW:
      return <EditConditionView />;

    default:
      return <></>;
  }
};

export default React.memo(ConditionCreationSidebar);
