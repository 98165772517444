import { Flex } from "@chakra-ui/react";
import { Typography } from "@reconvert/react-ui-component";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Error from "./Error";
import { useAppSelector } from "./app/hooks";
import { selectAppState } from "./app/slice/appSlice";
import Loading from "./components/CheckoutPage/components/Loading";
import PageLayout from "./components/PageLayout/PageLayout";
import useAppInit from "./hooks/useAppInit";
import "./utils/i18n";
import { APP_PREFIX } from "./config";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={APP_PREFIX} element={<Main />}></Route>
        <Route path="/Error" element={<Error />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const Main = () => {
  const { loading, error, activePage } = useAppSelector(selectAppState);

  useAppInit();

  if (loading || !activePage) {
    return (
      <Flex height="100vh" justifyContent="center" alignItems="center">
        <Loading />
      </Flex>
    );
  }

  if (error) {
    return (
      <Typography textAlign="center" variant="h2">
        Oops!! Access denied.
      </Typography>
    );
  }

  return <PageLayout />;
};

export default App;
