import { Button } from "@chakra-ui/react";
import { Modal } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAppState, toggleHelpModal } from "../../app/slice/appSlice";

const HelpModal = () => {
  const { t } = useTranslation();
  const { showHelpModal, helpArticleLink, helpArticleTitle } = useAppSelector(selectAppState);
  const dispatch = useAppDispatch();

  return (
    <Modal
      isOpen={showHelpModal}
      onClose={() => dispatch(toggleHelpModal())}
      closeOnEsc={true}
      title={helpArticleTitle ?? null}
      hideCloseButton={true}
      size="6xl"
      footer={
        <>
          <Button
            padding={"8px 16px"}
            borderRadius={"4px"}
            border={"1px solid var(--Neutral-500, #9EA7B6)"}
            variant={"secondary"}
            onClick={() => dispatch(toggleHelpModal())}
          >
            {t("Close")}
          </Button>
        </>
      }
    >
      <iframe src={helpArticleLink} title={helpArticleTitle} style={{ width: "100%", height: "70vh" }} />
    </Modal>
  );
};

export default HelpModal;
