import { Box, Flex, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import useConditions from "../../hooks/useConditions";
import { Content } from "../Content";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import { selectAppState } from "../../app/slice/appSlice";
import { useAppSelector } from "../../app/hooks";

const PageLayout = () => {
  const { iFrameLink } = useAppSelector(selectAppState);
  const { clostIFrame } = useConditions();

  const handleIFrameMessage = useCallback(
    (e: MessageEvent<any>) => {
      if (e.data.eventType === "exit" && e.data.data === true) {
        clostIFrame();
      }
    },
    [clostIFrame],
  );

  useEffect(() => {
    window.addEventListener("message", handleIFrameMessage);

    return () => {
      window.removeEventListener("message", handleIFrameMessage);
    };
  }, [handleIFrameMessage]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100vh",
      }}
    >
      <Header />
      <Flex height={"max-content"}>
        <Sidebar />
        <Content />
      </Flex>
      <Modal onClose={clostIFrame} size={"full"} isOpen={!!iFrameLink} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalBody w="100%" h="100%" m={0}>
            <iframe
              title="Checkout Editor Page"
              src={iFrameLink}
              allowFullScreen
              style={{ width: "100%", height: "100vh" }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PageLayout;
