import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
//@ts-ignore
import ReConvertLoading from "../../../assets/gif/ReConvert-Loading.gif";

const Loading = () => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <Box w="100vw" h="100vh" display="flex" flexDir="column" justifyContent="center" alignItems="center">
      <img src={ReConvertLoading} style={{ width: "180px" }} onLoad={handleImageLoad} alt="Loading Img" />
      {isLoaded && (
        <Typography variant="subheading1" fontWeight={500}>
          {t("ReConvert magic is on it's way!")}
        </Typography>
      )}
    </Box>
  );
};

export default Loading;
